import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import diamondImg from '../../Assets/diamond1.png';
import dataImg from '../../Assets/data.png';
import './RabbitHole.css';

const LinkText = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-decoration-line: underline;
  cursor: pointer;
  color: white; /* Default color */
  &:hover {
    color: #1ce783; /* Hover color */
  }
`;

const RabbitHole = () => {
  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <section>
      <Container fluid className="home-about">
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>SEEKER research</title>
            <link rel="canonical" href="http://seeker-flow.io" />
            <meta
              name="description"
              content="SEEKER journaling for mental health therapy"
            />
          </Helmet>
        </div>

        <Container>
          <Row>
            <Col md={7} className="rabbit-about-links2">
              <Fade direction="up">
                <div>
                  <h2 style={styles.sectionContentText}>
                    Chapter 1 - Down the Rabbit-Hole <br />
                    <p>
                      Alice had begun to think that very few things indeed were
                      really impossible.
                    </p>
                  </h2>
                </div>
              </Fade>
              <div className="statement">
                <p>
                  SEEKER's universe exists through the lens of evolutionary
                  pscyhology, robotics and physics. Our system evolves along
                  with you, filtering all the information that has ever existed
                  and focusing it solely on your well-being. It is a combination
                  of ancient wisdom and up to the moment scientific advancements
                  to help you find meaning in your everyday. <br /> <br />
                  We sit at the edge of technological breakthroughs daily as
                  embodied conscious agents are being built all around us. These
                  agents may one day mimic the data structures we have
                  discovered beyond spacetime, what Jung coined the Collective
                  Unconscious. That may be a topic for another time. For now, we
                  are focused on the potential of the human mind and the next
                  phase of consciousness. <br /> <br />
                  While we watch the billionaires blast off on their spaceships
                  towards other planets, we will explore the latent spaces of
                  artificial and biological realities in a small attempt to help
                  save ours. These are a few touchpoints that help me see the
                  possibility of our species. It takes a long time to become who
                  we are.
                </p>
              </div>

              <div style={styles.bookContainer}>
                <LinkText
                  onClick={() =>
                    openLink(
                      'https://www.amazon.com/Programming-Metaprogramming-Human-Biocomputer-Experiments/dp/0692217894'
                    )
                  }
                >
                  Programming and Metaprogramming in the Human Biocomputer:
                  Theory and Experiments
                </LinkText>
                <p style={styles.authorText}>
                  John C. Lilly was an American physician, neuroscientist, and a
                  pioneer in the field of psychedelic drugs. While working for
                  the CIA he invented the isolation tank and the first brain
                  implants.
                </p>

                <LinkText
                  onClick={() =>
                    openLink(
                      ' https://www.amazon.com/Case-Against-Reality-Evolution-Truth/dp/0393254690'
                    )
                  }
                >
                  The Case Against Reality: Why Evolution Hid the Truth from Our
                  Eyes
                </LinkText>
                <p style={styles.authorText}>
                  Donald Hoffman is a cognitive psychologist and a Professor in
                  the Department of Cognitive Sciences at the University of
                  California, Irvine, with joint appointments in the Department
                  of Philosophy, the Department of Logic and Philosophy of
                  Science, and the School of Computer Science.
                </p>

                <LinkText onClick={() => openLink('https://chrisbache.com/')}>
                  LSD and the Mind of the Universe: Diamonds from Heaven
                </LinkText>
                <p style={styles.authorText}>
                  Chris Bache is a professor emeritus at Youngstown State.
                  Beyond physics, religion and philosopy this book describes one
                  man's extraordinatry journey outside of time.
                </p>

                {/* <LinkText
                  onClick={() =>
                    openLink(
                      'https://www.amazon.com/Alien-Information-Theory-Psychedelic-Technologies/dp/1527234762'
                    )
                  }
                >
                  Alien Information Theory: Psychedelic Drug Technologies and
                  the Cosmic Game
                </LinkText> */}
                <p style={styles.authorText}>
                  Andrew Gallimore is a Computational Neurobiologist,
                  pharmacologist and author of Alien Information Theory &
                  Reality Switch Technologies.
                </p>

                <LinkText onClick={() => openLink('https://maps.org/')}>
                  The Reality of ESP: A Physicist’s Proof of Psychic Abilities
                </LinkText>
                <p style={styles.authorText}>
                  Russell Targ is a physicist and author, a pioneer in the
                  development of the laser and laser applications, and a
                  cofounder of the Stanford Research Institute (SRI)
                  investigation of psychic abilities in the 1970s and 1980s.
                </p>
                <LinkText
                  onClick={() =>
                    openLink(
                      'https://www.amazon.com/Swimming-Sacred-Wisdom-Psychedelic-Underground/dp/1608687309'
                    )
                  }
                >
                  Swimming in the Sacred: Wisdom from the Psychedelic
                  Underground
                </LinkText>
                <p style={styles.authorText}>
                  Rachel Harris, PhD is the author of Listening to Ayahuasca:
                  New Hope for Depression, Addiction, PTSD, and Anxiety.
                </p>
                <LinkText
                  onClick={() =>
                    openLink(
                      'http://cultureandcommunication.org/deadmedia/index.php/Experiential_Typewriter'
                    )
                  }
                >
                  The Experiential Typewriter
                </LinkText>
                <p style={styles.authorText}>
                  Timothy Leary was an American psychologist and writer and the
                  man who turned Ram Dass on.
                </p>
              </div>
              <p className="reason">This is what your data looks like.</p>
              <img src={dataImg} className="therapist" alt="therapist" />
              <p>
                Ultimately this is a continuation of other's work with an eye
                towards the potential of Dr. Rick Strassman's DMTx research at
                the Imperial College of London and utilizing Joscha Bach's view
                of AI and a computational reality to move beyond duality. As
                John Vervaake has defined our meaning crisis, SEEKER is here to
                help you define the things that truly matter to you and help you
                live the life you really want, versus what we've been told to
                believe.
              </p>
            </Col>
            <Col md={5} className="rabbit-about-description2">
              <div>
                <img src={diamondImg} className="therapist" alt="therapist" />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
};

const styles = {
  sectionContentText: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 30,
    color: '#1ce783',
  },
  bookContainer: {
    marginBottom: 20,
  },
  linkText: {
    fontSize: 16,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  authorText: {
    fontSize: 14,
  },
};

export default RabbitHole;
