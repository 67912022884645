import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import './NavBar.css';

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener('scroll', scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? 'sticky' : 'navbar'}
    >
      <Container>
        <Navbar.Brand
          href="https://seeker-flow.io"
          style={{ fontSize: '36px' }}
        >
          <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
            SEEKER
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => updateExpanded(expand ? false : 'expanded')}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/application"
                onClick={() => updateExpanded(false)}
              >
                Experience
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/philosophy"
                onClick={() => updateExpanded(false)}
              >
                Philosophy
              </Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/platform"
                onClick={() => updateExpanded(false)}
              >
                Integration
              </Nav.Link>
            </Nav.Item> */}

            <Nav.Item>
              <Dropdown onSelect={() => updateExpanded(false)}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-basic"
                  className="dropdown-toggle-link"
                >
                  Research and Resources
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    as={Link}
                    to="/research"
                    className="dropdown-link"
                  >
                    Research
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/resources"
                    className="dropdown-link"
                  >
                    Resources
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/rabbitHole"
                    className="dropdown-link"
                  >
                    Rabbit Hole
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
